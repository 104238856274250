import React, {useEffect, useRef, useState } from 'react';
import {CoordinateApi, Parent, ParentApi} from "./Service/OpenApi";
import RegisterParent from "./RegisterParent";
import logo from './images/location.gif';
import logoDisable from './images/location_diable.png';
import docPDPA from './assets/pdpa.pdf';
import {useAuth} from "react-oidc-context";
import {config} from "./config/ApiConfig";
import {Avatar} from "primereact/avatar";

export default function UserLocation() {
    const auth = useAuth();
    const [distance, setDistance] = useState<number>(9999);
	const [sendTime, setSendTime] = useState("00:00:00 AM");
    const [location, setLocation] = useState<GeolocationCoordinates | null>(null);
    const [parent, setParent] = useState<Parent | null>(null);
    const [isError, setIsError] = useState(false);
    const turf = require('@turf/turf');
    let polygons = useRef<[][][]>([]);

    useEffect(() => {
        if (!auth.user) return;
        (new ParentApi(config(auth))).getParent().subscribe({
            next: u => setParent(u), error: err => {
                setIsError(true)
            }
        });
        let interval: NodeJS.Timer;
        navigator.geolocation.getCurrentPosition(position => {
            (new CoordinateApi(config(auth))).getParentCarDetectionZone().subscribe(p => {
                setLocation(position.coords);
                polygons.current = p.map(s => turf.polygon([s]));
                //let point = turf.point([100.5655922, 13.8504562]); //always in zone
                let point = turf.point([position.coords.longitude, position.coords.latitude]);
				let thisTime = new Date();
                setSendTime(thisTime.toLocaleTimeString());
                for (let i = 0; i < polygons.current.length; i++) {
                    if (turf.booleanPointInPolygon(point, polygons.current[i])) {
                        let gate4 = turf.point([100.5695140654414, 13.85178248616729]);
                        let distance = Math.floor(turf.distance(gate4, point, {units: 'kilometers'}) * 1000);
                        setDistance(distance);
                        (new CoordinateApi(config(auth))).addInbound({distance}).subscribe();
                        break;
                    }
                }
            });
        });

        interval = setInterval(() => {
            navigator.geolocation.getCurrentPosition(position => {
                setLocation(position.coords);
                let thisTime = new Date();
                setSendTime(thisTime.toLocaleTimeString());
                //let point = turf.point([100.5655922, 13.8504562]); //always in zone
                let point = turf.point([position.coords.longitude, position.coords.latitude]);
                for (let i = 0; i < polygons.current.length; i++) {
                    if (turf.booleanPointInPolygon(point, polygons.current[i])) {
                        let gate4 = turf.point([100.5695140654414, 13.85178248616729]);
                        let distance = Math.floor(turf.distance(gate4, point, {units: 'kilometers'}) * 1000);
                        setDistance(distance);
                        (new CoordinateApi(config(auth))).addInbound({distance}).subscribe();
                        break;
                    }
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    }, [auth, turf]);

    return (
        isError ? (
            <div className="overflow-hidden flex flex-col justify-between items-center pt-5">
                <div className="flex justify-center items-center">
                    <p><span className="text-red-600">ไม่สามารถเชื่อมต่อกับ Server</span></p>
                </div>
                <div className="flex items-center">
                    <p><span className="text-red-600">เวลาทำการของระบบ: จ.-ศ. / 15.45-16.30น.</span></p>
                </div>
            </div>
        ) : (
            parent?.fullnameTh == null ? (<RegisterParent parent={parent} setParent={setParent}/>) :
                (
                    parent.parentStudent?.length === 0 ? (
                            <div className="overflow-hidden h-screen flex flex-col items-center pt-32 text-center">
                                <Avatar icon="pi pi-exclamation-triangle" size="xlarge" shape="circle" style={{color: 'orange'}}/>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">ข้อมูลของท่านยังไม่ได้ผูกกับนักเรียน <br/>กรุณาติดต่อเจ้าหน้าที่ธุรการของโรงเรียน</h2>
                            </div>
                        ) :
                        (
                            <div className="overflow-hidden h-screen flex flex-col justify-between items-center pt-5">
                                <div className="flex justify-center items-center">
                                    {location?.latitude != null ?
                                        <img src={logo} alt="logo" className="w-40 h-40 rounded-full"/>
                                        :
                                        <img src={logoDisable} alt="logo" className="w-40 h-40 rounded-full"/>}
                                </div>
                                <div>
                                    {location?.latitude != null ?
                                        <div className="text-center">
                                            {distance < 999 ?
                                                <div>
                                                  ระยะห่าง:({distance})ม. (Send Time: {sendTime})
                                                </div> :
                                                <div>
                                                    <b>คุณอยู่นอกระยะที่กำหนดไว้ (Send Time: {sendTime})</b>
                                                </div>
                                            }
                                            <p className="text-red-600">กรุณาเปิดหน้าจอนี้ไว้ตลอดเวลา มิฉะนั้นโปรแกรมจะหยุดทำงาน <br/>และหากเวลาด้านบนไม่เปลี่ยนทุก5วินาที กรุณาแจ้งทีม IT</p>
                                        </div>
                                        :
                                        <div className="text-center">
                                            <span className="text-red-600">
                                                <p>ไม่สามารถค้นหาตำแหน่งได้ กรุณาตรวจสอบการอนุญาติการเข้าถึง Location GPS</p>
                                            </span>
                                        </div>
                                    }
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={auth.user?.profile.picture![0]} alt="User profile" className="w-10 h-10 rounded-full"/>
                                    <div className="pl-2">{auth.user?.profile.name}</div>
                                </div>
                                <div className="text-center text-purple-700 bg-white w-96">
                                    <div>
                                        {parent.parentStudent?.map((ps, i) => (<p key={i}><b>{ps?.student?.fullnameTh + ' ' + ps.student?.classroom}</b></p>))}
                                    </div>
                                </div>
                                <footer >
                                        <div className = "App">
                                            <p><a href = {docPDPA} target = "_blank" rel="noreferrer">ดูนโยบายคุ้มครองข้อมูลส่วนบุคคล</a></p>
                                            <p className="text-xs/[10px] text-gray-400"
                                               title="route animated icons">Route animated icons created by Freepik - Flaticon</p>
                                        </div>

                                </footer>

                            </div>

                        )
                )
        )
    )
        ;
}