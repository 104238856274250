// tslint:disable
/**
 * auto-announce-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders } from '../runtime';
import type {
    Student,
} from '../models';

export interface GetStudentRequest {
    id: number;
}

export interface PutStudentRequest {
    student?: Student;
}

/**
 * no description
 */
export class StudentApi extends BaseAPI {

    /**
     */
    getStudent({ id }: GetStudentRequest): Observable<Student>
    getStudent({ id }: GetStudentRequest, opts?: OperationOpts): Observable<AjaxResponse<Student>>
    getStudent({ id }: GetStudentRequest, opts?: OperationOpts): Observable<Student | AjaxResponse<Student>> {
        throwIfNullOrUndefined(id, 'id', 'getStudent');

        return this.request<Student>({
            url: '/student/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    putStudent({ student }: PutStudentRequest): Observable<Student>
    putStudent({ student }: PutStudentRequest, opts?: OperationOpts): Observable<AjaxResponse<Student>>
    putStudent({ student }: PutStudentRequest, opts?: OperationOpts): Observable<Student | AjaxResponse<Student>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<Student>({
            url: '/student',
            method: 'PUT',
            headers,
            body: student,
        }, opts?.responseOpts);
    };

}
