import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from "./App";
import {OidcConfig} from "./config/OidcConfig";
import {AuthProvider} from "react-oidc-context";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    //<React.StrictMode>
    <AuthProvider {...OidcConfig} onSigninCallback={() => {
        window.location.replace('/')
    }}>
        <App/>
    </AuthProvider>
    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
