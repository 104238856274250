import {UserManagerSettings, WebStorageStateStore} from 'oidc-client-ts';

export const OidcConfig: UserManagerSettings = {
    authority: 'https://access.line.me',
    client_id: '1661214356',
    redirect_uri: `${window.location.origin}`,
    post_logout_redirect_uri: `${window.location.origin}`,
    scope: 'openid profile email',
    response_type: 'code',
    automaticSilentRenew: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({store: window.localStorage}),
    metadata: {
        issuer: 'https://access.line.me',
        authorization_endpoint: 'https://access.line.me/oauth2/v2.1/authorize',
        token_endpoint: 'https://api.line.me/oauth2/v2.1/token',
        revocation_endpoint: 'https://api.line.me/oauth2/v2.1/revoke',
        userinfo_endpoint: 'https://api.line.me/oauth2/v2.1/userinfo',
        scopes_supported: ['openid', 'profile', 'email'],
        jwks_uri: 'https://api.line.me/oauth2/v2.1/certs',
        response_types_supported: ['code'],
        subject_types_supported: ['pairwise'],
        code_challenge_methods_supported: ['S256']
    }
};