import React, {ChangeEvent, useRef, useState} from 'react';
import {Parent, ParentApi} from "./Service/OpenApi";
import {config} from "./config/ApiConfig";
import {useAuth} from "react-oidc-context";
import {Controller, useForm} from "react-hook-form";
import {Toast} from "primereact/toast";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";

interface RegisterParentProps {
    parent: Parent | null;
    setParent: React.Dispatch<React.SetStateAction<Parent | null>>;
}

type FormData = {
    firstName: string;
    lastName: string;
};
export default function RegisterParent(props: RegisterParentProps) {
    const auth = useAuth();
    const [parentForm, setParentForm] = useState({
        firstName: '',
        lastName: ''
    });
    const {handleSubmit, control, formState: {errors}} = useForm<FormData>();
    const toast = useRef<Toast>(null);
    const accept = () => {
        register();
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setParentForm({
            ...parentForm,
            [event.target.name]: value
        });
    }

    const reject = () => {
        toast.current?.show({severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000});
    }
    const confirm1 = () => {
        confirmDialog({
            message: 'ยืนยันการลงทะเบียน?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    };
    const onSubmit = (data: FormData) => {
        console.log(data);
        confirm1();
        // your form submission logic here
    };

    function register() {
        (new ParentApi(config(auth))).putParent({
            parent: {
                lineSub: auth.user?.profile.sub,
                lineName: auth.user?.profile.name,
                lineEmail: auth.user?.profile.email,
                fullnameTh: parentForm.firstName + ' ' + parentForm.lastName,
            }
        }).subscribe({
            next: (parent) => props.setParent(parent), error: () => confirmDialog({
                message: 'เกิดข้อผิดพลาดในการลงทะเบียน',
                header: 'Error',
                icon: 'pi pi-exclamation-triangle',
                accept,
                reject
            })
        });
    }

    return (
        <div className="overflow-hidden h-screen flex flex-col items-center">
            <div className="border-b border-gray-900/10 pb-12">
                <div className="isolate px-6 py-24 sm:py-32 lg:px-8 text-center">
                    <Toast ref={toast}/>
                    <ConfirmDialog/>
                    <form onSubmit={handleSubmit(onSubmit)} action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
                        <h2 className="text-base font-semibold leading-7 text-red-700">ท่านยังไม่ได้ลงทะเบียน
                            กรุณากรอกข้อมูลให้ครบถ้วน</h2>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="first-name"
                                       className="block text-sm font-medium leading-6 text-gray-900">ชื่อจริงผู้ปกครอง<span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        defaultValue={parentForm.firstName}
                                        rules={{required: "This field is required"}}
                                        render={({field}) => (
                                            <input {...field} type="text" name="firstName" id="first-name"
                                                   value={parentForm.firstName}
                                                   onChange={(e) => {
                                                       field.onChange(e);
                                                       handleChange(e)
                                                   }
                                                   }
                                                   className="py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                           placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>)}
                                    ></Controller>
                                </div>
                                <span className="text-red-600">{errors.firstName && <p>*This field is required</p>}</span>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="last-name"
                                       className="block text-sm font-medium leading-6 text-gray-900">นามสกุลผู้ปกครอง<span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        defaultValue={parentForm.lastName}
                                        rules={{required: "This field is required"}}
                                        render={({field}) => (
                                            <input {...field} type="text" name="lastName" id="last-name"
                                                   value={parentForm.lastName}
                                                   onChange={(e) => {
                                                       field.onChange(e);
                                                       handleChange(e)
                                                   }
                                                   }
                                                   className="py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                           placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>)}
                                    ></Controller>
                                </div>
                                <span className="text-red-600">{errors.lastName && <p>*This field is required</p>}</span>
                            </div>
                        </div>
                        <div className="mt-6 flex items-center justify-center gap-x-6">
                            <button
                                type="submit"
                                className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Register
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}