// tslint:disable
/**
 * auto-announce-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    Pairing,
    Parent,
} from '../models';

export interface PutParentRequest {
    parent?: Parent;
}

export interface PutStudentsRequest {
    pairing?: Pairing;
}

export interface SearchParentRequest {
    text?: string;
}

/**
 * no description
 */
export class ParentApi extends BaseAPI {

    /**
     */
    getParent(): Observable<Parent>
    getParent(opts?: OperationOpts): Observable<AjaxResponse<Parent>>
    getParent(opts?: OperationOpts): Observable<Parent | AjaxResponse<Parent>> {
        return this.request<Parent>({
            url: '/parent',
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    putParent({ parent }: PutParentRequest): Observable<Parent>
    putParent({ parent }: PutParentRequest, opts?: OperationOpts): Observable<AjaxResponse<Parent>>
    putParent({ parent }: PutParentRequest, opts?: OperationOpts): Observable<Parent | AjaxResponse<Parent>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<Parent>({
            url: '/parent',
            method: 'PUT',
            headers,
            body: parent,
        }, opts?.responseOpts);
    };

    /**
     */
    putStudents({ pairing }: PutStudentsRequest): Observable<Parent>
    putStudents({ pairing }: PutStudentsRequest, opts?: OperationOpts): Observable<AjaxResponse<Parent>>
    putStudents({ pairing }: PutStudentsRequest, opts?: OperationOpts): Observable<Parent | AjaxResponse<Parent>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<Parent>({
            url: '/parent/students',
            method: 'PUT',
            headers,
            body: pairing,
        }, opts?.responseOpts);
    };

    /**
     */
    searchParent({ text }: SearchParentRequest): Observable<Array<Parent>>
    searchParent({ text }: SearchParentRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<Parent>>>
    searchParent({ text }: SearchParentRequest, opts?: OperationOpts): Observable<Array<Parent> | AjaxResponse<Array<Parent>>> {

        const query: HttpQuery = {};

        if (text != null) { query['text'] = text; }

        return this.request<Array<Parent>>({
            url: '/parent/search',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

}
