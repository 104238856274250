import React, {useEffect, useState} from 'react';
import './ArrivingList.css';
import {Divider} from "primereact/divider";
import {DashboardApi, Inbound} from "./Service/OpenApi";
import {config} from "./config/ApiConfig";
import {useAuth} from "react-oidc-context";
import {Carousel, CarouselResponsiveOption} from "primereact/carousel";
import {interval, switchMap} from "rxjs";

export default function ArrivingList() {
    const auth = useAuth();
    const [inboundValue, setInboundValue] = useState<Inbound[]>([]);
    const [, setIsError] = useState(false);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight - 100 + 'px');
    const responsiveOptions: CarouselResponsiveOption[] = [
        {
            breakpoint: '2600px',
            numVisible: 5,
            numScroll: 5
        },
        {
            breakpoint: '960px',
            numVisible: 5,
            numScroll: 5
        }
    ];
    // Effect for handling window resizing
    useEffect(() => {
        const handleResize = () => setViewportHeight(window.innerHeight - 100 + 'px');
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const subscription = interval(5000) // every 5 seconds
            .pipe(
                switchMap(() => (new DashboardApi(config(auth))).pickMeUpList())
            )
            .subscribe({
                next: u => setInboundValue(
                    u.filter(
                        (fl) => {
                            return fl.studentRfidAt != null;
                        }
                    ).sort((a, b) => {
                            if (a.studentRfidAt === undefined || a.studentRfidAt === null) return 1; // Move a to the end if a.parentDistance is null or undefined
                            if (b.studentRfidAt === undefined || b.studentRfidAt === null) return -1; // Move b to the end if b.parentDistance is null or undefined
                            return (new Date(a.studentRfidAt).getTime()) < (new Date(b.studentRfidAt).getTime())? 1:-1;
                        }
                    ).slice(0, 10).map(i => i!)),
                error: err => {
                    setIsError(true)
                }
            });

        // Cleanup subscription on unmount
        return () => subscription.unsubscribe();
    }, [auth]);

    let inboundValueWithRowNumber = inboundValue.map((inbound, index) => ({
        ...inbound,
        rowNumber: index + 1, // add 1 to start counting from 1 instead of 0
    }));
    const productTemplate = (inboundValue: Inbound & { rowNumber: number }) => {
        return (
            <div>
                <div className="text-arriving-list flex justify-between ">
                    <div>{inboundValue.rowNumber}. {inboundValue.student?.fullnameTh}</div>
                    <div><span className="text-blue-700"></span> {inboundValue.student?.classroom}</div>
                </div>
                <Divider type="dashed" className="myCustomDivider"/>
            </div>
        );
    };
    return (
        <div>
            <div className="font-bold">
                รายชื่อเด็กแตะบัตร
            </div>
            <div className="carousel-bg pl-3 pr-3">
                <Carousel value={inboundValueWithRowNumber} responsiveOptions={responsiveOptions} className="custom-carousel" orientation="vertical"
                          verticalViewPortHeight={viewportHeight}
                          itemTemplate={productTemplate}/>
            </div>
        </div>
    );
}