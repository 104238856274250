import {Configuration} from "../Service/OpenApi";
import {AjaxConfig} from "rxjs/ajax";
import {AuthContextProps} from "react-oidc-context";

export function config(auth: AuthContextProps): Configuration {
    return new Configuration({
        basePath: process.env.REACT_APP__API,
        middleware: [
            {
                pre(request): AjaxConfig {
                    return {
                        ...request,
                        headers: {
                            ...request.headers,
                            Authorization: `Bearer ${auth.user?.id_token}`,
                        },
                    };
                },
            },
        ],
    });
}
