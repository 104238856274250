// tslint:disable
/**
 * auto-announce-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI } from '../runtime';
import type { OperationOpts } from '../runtime';
import type {
    Inbound,
} from '../models';

/**
 * no description
 */
export class DashboardApi extends BaseAPI {

    /**
     */
    pickMeUpList(): Observable<Array<Inbound>>
    pickMeUpList(opts?: OperationOpts): Observable<AjaxResponse<Array<Inbound>>>
    pickMeUpList(opts?: OperationOpts): Observable<Array<Inbound> | AjaxResponse<Array<Inbound>>> {
        return this.request<Array<Inbound>>({
            url: '/dashboard/pick-me-up',
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    serverTime(): Observable<string>
    serverTime(opts?: OperationOpts): Observable<AjaxResponse<string>>
    serverTime(opts?: OperationOpts): Observable<string | AjaxResponse<string>> {
        return this.request<string>({
            url: '/dashboard/server-time',
            method: 'GET',
        }, opts?.responseOpts);
    };

}
