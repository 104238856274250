import React, {useEffect} from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ArrivingList from "./ArrivingList";
import ArrivingListMatch from "./ArrivingListMatch";
import RegisterStudent from "./RegisterStudent";
import ConfirmRegisterParent from "./ConfirmRegisterParent";
import UserLocation from "./UserLocation";
import {useAuth} from "react-oidc-context";
import Login from "./Login";


function App() {
    const auth = useAuth();

    useEffect(() => {
        let wakeLock: WakeLockSentinel | null  = null;

        const requestWakeLock = async () => {
            try {
                wakeLock = await navigator.wakeLock.request('screen');
                console.log('Wake Lock is active');
                document.addEventListener('visibilitychange', onVisibilityChange);
                window.addEventListener('pagehide', onPageHide);
            } catch (err) {
                console.error(`Failed to acquire wake lock: ${err}`);
            }
        };

        const onVisibilitySystem = () => {
            if (wakeLock !== null && document.visibilityState === 'visible') {
                requestWakeLock();
            }
        };

        const onVisibilityChange = () => onVisibilitySystem();
        const onPageHide = () => onVisibilitySystem();

        if ('wakeLock' in navigator) {
            requestWakeLock();
        }

        return () => {
            wakeLock?.release().then(() => {
                console.log('Wake Lock was released');
                document.removeEventListener('visibilitychange', onVisibilityChange);
                window.removeEventListener('pagehide', onPageHide);
                wakeLock = null;
            });
        };
    }, []);

    const router = createBrowserRouter([
        {
            path: "/",
            element: auth.user ? <UserLocation/> : <Login/>
        }, {
            path: "arriving-list",
            element: <ArrivingList/>
        }, {
            path: "arriving-list-match",
            element: <ArrivingListMatch/>
        }, {
            path: "register-student",
            element: <RegisterStudent/>
        }, {
            path: "confirm-register-parent",
            element: <ConfirmRegisterParent/>
        }
    ]);

    return (
        <div className="min-h-screen">
            <RouterProvider router={router}/>
        </div>
    );
}

export default App;
