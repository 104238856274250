import React, {ChangeEvent, useRef, useState} from 'react';
import {Student, StudentApi} from "./Service/OpenApi";
import {config} from "./config/ApiConfig";
import {useAuth} from "react-oidc-context";
import {Controller, useForm} from "react-hook-form";
import {Toast} from "primereact/toast";
import {Link} from "react-router-dom";

type FormData = {
    id: string;
    rfid: string;
    prefix: string;
    firstName: string;
    lastName: string;
    classroom: string;
};
export default function RegisterStudent() {
    const auth = useAuth();
    const [studentForm, setStudentForm] = useState({
        id: '',
        rfid: '',
        prefix: '',
        firstName: '',
        lastName: '',
        classroom: ''
    });

    const handleChange = (event: ChangeEvent<any>) => {
        const value = event.target.value;
        const name = event.target.name;
        if (value.length !== 0 && !value.match(/^\d*$/) && ['id', 'rfid'].includes(name)) {
            return
        }

        setStudentForm({
            ...studentForm,
            [event.target.name]: value
        });
    }

    const {handleSubmit, control, formState: {errors}, reset} = useForm<FormData>();
    const toast = useRef<Toast>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const showSuccess = () => {
        toast.current?.show({severity: 'success', summary: 'Success', detail: 'บันทึกข้อมูลสำเร็จ!!', life: 3000});
    }
    const showError = () => {
        toast.current?.show({severity: 'error', summary: 'Error', detail: 'ไม่สามารถบันทึกได้ ตรวจสอบข้อมูลอีกครั้ง', life: 3000});
    }
    const onSubmit = (data: FormData) => {
        //console.log(data);
        //Lock button
        // your form submission logic here
        submitRegister();

        //unlock button
    };

    function submitRegister() {
        setIsSubmitting(true); // Disable the submit button
        (new StudentApi(config(auth))).putStudent({
            student: {
                id: Number(studentForm.id),
                rfid: studentForm.rfid,
                fullnameTh: studentForm.prefix + studentForm.firstName + ' ' + studentForm.lastName,
                classroom: studentForm.classroom
            }
        }).subscribe({
            next:(res: Student) =>{
                if(res){
                    showSuccess();
                    reset();
                }
                else{
                    showError();
                }
            },
            complete: () => {
                setIsSubmitting(false); // Enable the submit button
            }
        });
    }

    return (
        <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
            <Toast ref={toast}/>
            <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
                <div
                    className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-80rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(4.1% 24.1%, 100% 11.6%, 97.5% 36.9%, 75.5% 60.1%, 80.7% 12%, 72.5% 32.5%, 60.2% 82.4%, 2.4% 88.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">ลงทะเบียนข้อมูลนักเรียน</h2>
                <p className="mt-2 text-lg leading-8 text-red-600">* = จำเป็นต้องกรอก</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                        <label htmlFor="student-id" className="block text-sm font-semibold leading-6 text-gray-900">
                            รหัสประจำตัว เด็กนักเรียน 10 หลัก<span className="text-red-600">*</span>
                        </label>
                        <div className="mt-2.5">
                            <Controller
                                name="id"
                                control={control}
                                defaultValue={studentForm.id}
                                rules={{required: true}}
                                render={({field}) => (
                                    <input
                                        {...field}
                                        type="text"
                                        name="id"
                                        className="py-2 px-3.5 text-gray-900 p-inputtext"
                                        maxLength={10}
                                        minLength={10}
                                        onChange={(e) => {
                                            field.onChange(e); // This will take care of updating the input value in react-hook-form
                                            handleChange(e); // This will update the input value in your local state
                                        }}
                                    />
                                )}
                            ></Controller>
                        </div>
                        <span className="text-red-600">{errors.id && <p>*This field is required</p>}</span>
                    </div>
                    <div>
                        <label htmlFor="student-rfid" className="block text-sm font-semibold leading-6 text-gray-900">
                            รหัส RFID<span className="text-red-600">*</span>
                        </label>
                        <div className="mt-2.5">
                            <Controller
                                name="rfid"
                                control={control}
                                defaultValue={studentForm.rfid}
                                rules={{required: true}} // this line is for validation
                                render={({field}) => (
                                    <input
                                        {...field}
                                        type="text"
                                        name="rfid"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChange(e)
                                        }}
                                        className="py-2 px-3.5 text-gray-900 p-inputtext"
                                        maxLength={10}
                                        minLength={10}
                                    />)}
                            ></Controller>
                        </div>
                        <span className="text-red-600">{errors.rfid && <p>*This field is required</p>}</span>
                    </div>
                    <div>
                        <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                            ชื่อจริง<span className="text-red-600">*</span>
                        </label>
                        <div className="relative mt-2.5">
                            <div className="absolute inset-y-0 left-0 flex items-center">
                                <label htmlFor="prefix" className="sr-only">
                                    คำนำหน้าชื่อ
                                </label>
                                <Controller
                                    name="prefix"
                                    control={control}
                                    defaultValue={studentForm.prefix}
                                    rules={{required: true}} // this line is for validation
                                    render={({field}) => (
                                        <select
                                            {...field}
                                            id="prefix"
                                            name="prefix"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleChange(e)
                                            }}
                                            className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                        >
                                            <option></option>
                                            <option>ด.ช.</option>
                                            <option>ด.ญ.</option>
                                        </select>)}
                                ></Controller>
                            </div>
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue={studentForm.firstName}
                                rules={{required: true}} // this line is for validation
                                render={({field}) => (
                                    <input
                                        {...field}
                                        type="text"
                                        name="firstName"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChange(e)
                                        }}
                                        className="px-2.5 py-1.5 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />)}
                            ></Controller>
                        </div>
                        <span className="text-red-600">{(errors.prefix || errors.firstName) && <p>*This field is required</p>}</span>
                    </div>
                    <div>
                        <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                            นามสกุล<span className="text-red-600">*</span>
                        </label>
                        <div className="mt-2.5">
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue={studentForm.lastName}
                                rules={{required: true}} // this line is for validation
                                render={({field}) => (
                                    <input
                                        {...field}
                                        type="text"
                                        name="lastName"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChange(e)
                                        }}
                                        className="py-2 px-3.5 text-gray-900 p-inputtext"
                                    />)}
                            ></Controller>
                        </div>
                        <span className="text-red-600">{errors.lastName && <p>*This field is required</p>}</span>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="student-class" className="block text-sm font-medium leading-6 text-gray-900">ชั้นปี<span className="text-red-600">*</span></label>
                        <div className="mt-2">
                            <Controller
                                name="classroom"
                                control={control}
                                defaultValue={studentForm.classroom}
                                rules={{required: true}} // this line is for validation
                                render={({field}) => (
                                    <select
                                        {...field}
                                        id="classroom"
                                        name="classroom"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChange(e)
                                        }}
                                        className="py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                        <option></option>
                                        <option>ป.2/1</option>
                                        <option>ป.2/2</option>
                                        <option>ป.2/3</option>
                                        <option>ป.2/4</option>
                                        <option>ป.2/5</option>
                                        <option>ป.2/6</option>
                                        <option>ป.2/7</option>
                                    </select>)}
                            ></Controller>
                        </div>
                        <span className="text-red-600">{errors.classroom && <p>*This field is required</p>}</span>
                    </div>
                </div>
                <div className="mt-10">
                    <button type="submit"
                            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            disabled={isSubmitting}
                    >
                        Register
                    </button>
                </div>
                <div className="flex justify-center items-center mb-4">
                    <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        <Link to='/arriving-list-match'>Arriving list Match</Link>
                    </button>
                    <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        <Link to='/confirm-register-parent'>Confirm Register Parent</Link>
                    </button>
                </div>
            </form>
        </div>
    )
}
