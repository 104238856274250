import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {catchError, debounceTime, filter, Subject, switchMap} from "rxjs";
import {Parent, ParentApi, ParentStudent, Student, StudentApi} from "./Service/OpenApi";
import {config} from "./config/ApiConfig";
import {useAuth} from "react-oidc-context";
import {map} from "rxjs/operators";
import {useForm, Controller} from 'react-hook-form';
import {AutoComplete, AutoCompleteChangeEvent} from 'primereact/autocomplete';
import { InputText } from 'primereact/inputtext';
import {Toast} from 'primereact/toast';

type FormData = {
    student: Student[];
    parent: Parent;
    parentRFID: string;
};

export default function ConfirmRegisterParent() {
    const auth = useAuth();

    const studentInput$ = useMemo<Subject<string>>(() => new Subject<string>(), []);
    const [studentValue, setStudentValue] = useState<Student[]>([]);
    const [studentItems, setStudentItems] = useState<Student[]>([]);


    const parentInput$ = useMemo<Subject<string>>(() => new Subject<string>(), []);
    const [parentValue, setParentValue] = useState<Parent>();
    const [parentItems, setParentItems] = useState<Parent[]>([]);

    const [parentRFIDValue, setParentRFIDValue] = useState<string>("");

    const {handleSubmit, control , formState: {errors},reset} = useForm<FormData>();
    const toast = useRef<Toast>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const showSuccess = () => {
        toast.current?.show({severity: 'success', summary: 'Success', detail: 'บันทึกข้อมูลสำเร็จ!!', life: 3000});
    }
    const showError = () => {
        toast.current?.show({severity: 'error', summary: 'Error', detail: 'ไม่สามารถบันทึกได้ ตรวจสอบข้อมูลอีกครั้ง', life: 3000});
    }
    const onSubmit = (data: FormData) => {
        //console.log(data);
        // your form submission logic here
        submitRegister();
    };
    const Checkbox = ({children, ...props}: JSX.IntrinsicElements['input']) => (
        <label style={{marginRight: '1em'}}>
            <input type="checkbox" {...props} />
            {children}
        </label>
    );
    useEffect(() => {
        if (!auth.user) return;
        const studentApi = new StudentApi(config(auth));
        const parentApi = new ParentApi(config(auth));
        const parentSubscription = parentInput$.pipe(
            filter(text => text.length >= 3),
            debounceTime(1000),
            switchMap(text => parentApi.searchParent({text: text}))
        ).subscribe({
            next: results => {
                setParentItems(results)
            },
            error: () => setParentItems([])
        });
        const studentSubscription = studentInput$.pipe(
            filter(text => text.length === 10),
            debounceTime(1000),
            map(t => Number(t)),
            switchMap(id => studentApi.getStudent({id: id}).pipe(catchError(_ => []))),
            map(s => [s])
        ).subscribe({
            next: results => {
                setStudentItems(results)
            },
        });

        return () => {
            studentSubscription.unsubscribe();
            parentSubscription.unsubscribe();
        }
    }, [auth, parentInput$, studentInput$]);

    function selectParent(e: AutoCompleteChangeEvent) {
        setParentValue(e.value);
        setStudentValue(e.value?.parentStudent?.map((ps: ParentStudent) => ps.student));
    }

    function submitRegister() {
        setIsSubmitting(true); // Disable the submit button
        (new ParentApi(config(auth))).putStudents({
            pairing: {sub: parentValue?.lineSub, studentId: studentValue?.map(s => s.id!),rfid: parentRFIDValue}
        }).subscribe({
            next: (res) => {
                if (res) {
                    showSuccess();
                    reset({
                        student: [], // Set the student field to an empty array
                        parent: undefined, // Set the parent field to undefined
                        parentRFID: "" // Set the parentRFID field to an empty string
                    });
                }
                else{
                    showError();
                }
            },
            complete: () => {
                setIsSubmitting(false); // Enable the submit button
            }
        });
    }


    return (
        <div className="isolate px-6 py-24 sm:py-32 lg:px-8">
            <Toast ref={toast}/>
            <div
                className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                aria-hidden="true"
            >
                <div
                    className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#fffa52] to-[#fc89b3] opacity-30 sm:left-[calc(50%-80rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(73.1% 44.1%, 98% 59.6%, 97.5% 74.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 62.4% 78.1%, 47.5% 78.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 34.1% 64.1%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">จับคู่ผู้ปกครอง -
                    นักเรียน</h2>
                <p className="mt-2 text-lg leading-8 text-gray-600">

                </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6">
                    <div>
                        <label htmlFor="student-id" className="block text-sm font-semibold leading-6 text-gray-900">
                            ชื่อผู้ปกครอง (หากมีผู้ปกครองหลายคน ต้องเพิ่มทีละคน)
                        </label>
                        <div className="mt-2.5">
                            <Controller
                                name="parent"
                                control={control}
                                defaultValue={parentValue}
                                rules={{required: true}} // this line is for validation
                                render={({field}) => (
                                    <AutoComplete forceSelection
                                                  field="fullnameTh"
                                                  placeholder="พิมพ์ชื่อ"
                                                  itemTemplate={(i: Parent) => i.fullnameTh + ',' + i.lineName ?? ''}
                                                  value={field.value}
                                                  suggestions={parentItems}
                                                  completeMethod={e => parentInput$.next(e.query)}
                                                  onChange={(e) => {
                                                      selectParent(e);
                                                      field.onChange(e.value);
                                                  }
                                                  }
                                    ></AutoComplete>
                                )}
                            ></Controller>
                        </div>
                        <span className="text-red-600">{errors.parent && <p>*This field is required</p>}</span>
                    </div>
                    <div>
                        <label htmlFor="student-id" className="block text-sm font-semibold leading-6 text-gray-900">
                            รหัสประจำตัว เด็กนักเรียน 10 หลัก (ใส่ได้หลายคน)
                        </label>
                        <div className="mt-2.5">
                            <Controller
                                name="student"
                                control={control}
                                defaultValue={[]}
                                rules={{required: true}} // this line is for validation
                                render={({field}) => (
                                    <AutoComplete multiple
                                                  field="fullnameTh"
                                                  placeholder="ใส่รหัส 10 ตัว"
                                                  itemTemplate={(i: Student) => i.id + ' ' + i.fullnameTh}
                                                  value={field.value}
                                                  suggestions={studentItems}
                                                  completeMethod={e => studentInput$.next(e.query)}
                                                  onChange={(e) => {
                                                      setStudentValue(e.value);
                                                      field.onChange(e.value);
                                                  }
                                                  }
                                    />
                                )}
                            ></Controller>
                        </div>
                        <span className="text-red-600">{errors.student && <p>*This field is required</p>}</span>
                    </div>
                    <div>
                        <label htmlFor="parent-rfid" className="block text-sm font-semibold leading-6 text-gray-900">
                            RFID บัตรผู้ปกครอง (ถ้ามี)
                        </label>
                        <div className="mt-2.5">
                            <Controller
                                name="parentRFID"
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <InputText {...field} type="text"
                                               onChange={(e) => {
                                                   field.onChange(e.target.value);
                                                   setParentRFIDValue(e.target.value); // Store the value in the state variable
                                               }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <Checkbox
                            name="parent-primary" id="parent-primary"
                            defaultChecked={true}
                        >
                            <span className="pl-2">รับข้อความ Line จากนักเรียน?</span>
                        </Checkbox>
                    </div>
                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <button
                            type="submit"
                            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            disabled={isSubmitting}
                        >
                            Register
                        </button>
                        <Link to='/register-student'>Register Student</Link>
                    </div>
                </div>
            </form>
        </div>
    );
}
