import React from 'react';
import {useAuth} from "react-oidc-context";

export default function Login() {
    const auth = useAuth();
    return (
        <div className="h-screen flex flex-col items-center pt-44">
            <div className="flex justify-center items-center">
                <img src="/kus_logo.png" alt="KUS logo"/>
            </div>
            <div className="text-center text-white my-4">
                <div>KUS PTA</div>
            </div>
            <button
                type="button"
                onClick={() => auth.signinRedirect()}
                className="inline-flex items-center gap-x-2 rounded-md bg-[#06C755] px-3 py-2 text-sm font-semibold text-white">
                <img src="/line_32.png" className="-ml-0.5" alt="line logo"/>
                Login with LINE
            </button>
        </div>
    );
}